import React from 'react';
import { Box, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Topfooter = () => {
    return (
        <Box sx={{ backgroundColor: '#000', color: '#fff' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                
                {/* Phone Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: { xs: 1, md: 10 } }}>
                    <a href="tel:+916369007033" style={{ color: '#fff', textDecoration: 'none',display:'flex' }}>
                        <PhoneIcon sx={{
                            marginRight: { xs: '5px', md: '8px' },
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                                md: '1.0rem',
                                lg: '1.1rem'
                            }
                        }} />
                        <Typography sx={{
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                                md: '1.0rem',
                                lg: '0.9rem'
                            },fontFamily: "'Poppins', sans-serif",
                        }}>
                            +91 6369007033
                        </Typography>
                    </a>
                </Box>

                {/* Location Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: { xs: 2, md: 5 } }}>
                    <a 
                        href="https://maps.app.goo.gl/nJLUVHzQ7LrNe1zT8" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ color: '#fff', textDecoration: 'none',display:'flex' }}
                    >
                        <LocationOnIcon sx={{
                            marginRight: '8px',
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                                md: '1.0rem',
                                lg: '0.9rem'
                            }
                        }} />
                        <Typography sx={{
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                                md: '1.0rem',
                                lg: '0.9rem'
                            },fontFamily: "'Poppins', sans-serif",
                        }}>
                            Virudhunager
                        </Typography>
                    </a>
                </Box>
                
            </Box>
        </Box>
    );
};

export default Topfooter;
