import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import HomePage from './pages/Home';
import AboutPage from './pages/Fea';
import GalleryPage from './pages/Gallery';
import ServicesPage from './pages/Service';

// Define unique animations for each route
const transitions = {
  home: {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  },
  about: {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  },
  gallery: {
    initial: { opacity: 0, x: -100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  },
  service: {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  },
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitions.home}
              transition={{ duration: 0.5 }}
            >
              <HomePage />
            </motion.div>
          }
        />
        
        <Route
          path="/features"
          element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitions.about}
              transition={{ duration: 0.5 }}
            >
              <AboutPage />
            </motion.div>
          }
        />
        <Route
          path="/gallery"
          element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitions.gallery}
              transition={{ duration: 0.5 }}
            >
              <GalleryPage />
            </motion.div>
          }
        />
        <Route
          path="/service"
          element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitions.service}
              transition={{ duration: 0.5 }}
            >
              <ServicesPage />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
