import React from 'react';
import CustomImageList from '../components/CustomImageList';
import Banner from '../components/Banner';
import { Container } from '@mui/material';
import Features from '../components/Features';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';



function Home() {
  return (
    <div>

      
      <Banner />
      <Features />
      <Testimonial />
      <Footer />
      {/* <CustomImageList /> */}
      
    </div>
  );
}

export default Home;
