import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container, Button, Grid } from '@mui/material';
import features from '../asset/features1.png';
import Footer from '../components/Footer';

function Fea() {
  return (
    <>
      <Container maxWidth={false} sx={{ py: 5, backgroundColor: '#F4F4F4' }}>
                <Container>
                    {/* Why Choose Us Section */}
                    <Grid container spacing={4}>
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <Box p={5} sx={{ padding: { xs: 0, md: 5 } }}>
                                <Typography variant="h6" fontWeight="bold" mb={3} color="error" sx={{ mb: 2, color: '#D2AC47', fontWeight: 500, fontFamily: "'Poppins', sans-serif", }} gutterBottom>
                                    Our Features
                                </Typography>
                                <Typography variant="h5" fontWeight="bold" mb={1} sx={{ fontFamily: "'Poppins', sans-serif", }}>
                                    Why choose Us
                                </Typography>
                                <Typography variant="body1" mb={3} sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    At Raja Gifts & customization, we pride ourselves on
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Quality & Uniqueness
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    Every item is carefully selected for its craftsmanship, quality, and charm.
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Exceptional Service
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    We’re here to help you find the perfect gift and make the experience enjoyable.
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Sustainable Choices
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    We care about the planet and seek out eco-friendly options whenever possible.
                                </Typography>

                            </Box>
                        </Grid>

                        {/* Right Column with Image */}
                        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                            <Box
                                component="img"
                                src={features}
                                sx={{
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: 2,
                                    maxWidth: '100%',
                                    height: 'auto',
                                }}
                            />
                        </Grid>
                    </Grid>


                </Container>
            </Container>
      <Footer />
    </>
  );
}

export default Fea;
