import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Container, Grid, Typography } from '@mui/material';
import logo from '../asset/logo1.jpg';
import logo1 from '../asset/shop1.jpg';
import { Card, CardMedia, CardContent, Button } from "@mui/material";
import "./customSlider.css";
import logo2 from '../asset/services/image (22).jpg';
import logo3 from '../asset/services/image (3).png';
import logo5 from '../asset/services/image (5).png';
import logo6 from '../asset/services/image (6).png';
import back from '../asset/back.jpg';
const services = [
  {
    title: "Gift Wrapping & Packaging",
    image: logo2
  },
  {
    title: "Personalized & Custom Gifts",
    image: logo3
  },
  {
    title: "Corporate Gifting Solutions",
    image: logo6
  },
  // {
  //   title: "Gift Consultation Services",
  //   image: logo4
  // },
  {
    title: "Event Favors & Bulk Orders",
    image: logo5
  },
  // {
  //   title: "Subscription Boxes",
  //   image: logo7
  // },
];

const Banner = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)");
    const handleResize = () => setIsLargeScreen(mediaQuery.matches);

    handleResize(); // Set initial value
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, // Set auto-slide interval (in milliseconds)
    pauseOnHover: true, // Pause auto-slide on hover
    customPaging: (i) => (
      <div className="custom-dot"></div> // Custom dot styling
    ),
  };
  return (
    <>
      <Box
        sx={{
          height: { xs: '60vh', md: '80vh' },
          backgroundImage: `
          linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
          url(${logo1})
        `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }}
      >
        <img
          src={logo}
          alt="Company Logo"
          style={{
            maxWidth: '180px',
            height: 'auto',
            borderRadius: '50%',

          }}
        />
      </Box>

      <Container maxWidth={false} sx={{
        backgroundImage: `
        linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
        url(${back})
      `,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        py:5
      }}>
        <Container>
          <Grid container justifyContent="center" textAlign="center" >
            <Grid item xs={12} md={8}>
              <Typography

                gutterBottom
                sx={{
                  mb: 4,
                  fontFamily: "'Sedan SC', sans-serif",
                  fontSize: { xs: '6vw', sm: '5vw', md: '2.5rem' }, // Responsive font size
                  lineHeight: { xs: '1.2', md: '1.3' }
                }}
              >
                Welcome to Raja Gifts & Customization
              </Typography>
              <Typography

                gutterBottom
                sx={{
                  wordSpacing: '0.1em',
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: { xs: '3.5vw', sm: '2vw', md: '1.25rem' }, // Responsive font size

                }}
              >
                At Raja Gifts & customization, we believe in the power of thoughtful gifts to create special moments and meaningful connections. Whether it’s a birthday, holiday, or “just because,” our collection is curated to bring joy, inspiration, and a little magic to every occasion.
              </Typography>
            </Grid>
          </Grid>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mt: 5 }}>
            <Box id="our-services">
              <Typography variant="h6" sx={{ color: '#D2AC47', fontWeight: 500, fontWeight: 500, fontFamily: "'Poppins', sans-serif" }} gutterBottom>
                Our Services
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={{
              color: '#000000', fontWeight: 500, fontFamily: "'Poppins', sans-serif",
              fontSize: { xs: '3.5vw', sm: '2vw', md: '1.25rem' },
            }} gutterBottom>
              We're here to make your gifting experience as effortless and delightful as possible
            </Typography>
          </Box>

          {!isLargeScreen && (
            <Box sx={{ my: 5 }}>
              <Slider {...sliderSettings}>
                {services.map((service, index) => (
                  <div key={index}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "none",
                        borderRadius: "10px",
                        border: "1px solid #D2AC47",
                        margin: "0 10px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="250"
                        image={service.image}
                        alt={service.title}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          align="center"
                          sx={{
                            fontWeight: 500,
                            fontFamily: "'Poppins', sans-serif",
                          }}
                          fontSize={{ xs: "1rem", sm: "1.05rem", md: "1.1rem" }}
                        >
                          {service.title}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Box>
          )}


          {/* Grid for larger devices */}
          {isLargeScreen && (
            <Grid container spacing={4} sx={{ mb: 4, mt: 2 }}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: "1px solid #D2AC47",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="250"
                      image={service.image}
                      alt={service.title}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        align="center"
                        sx={{
                          fontWeight: 500,
                          fontFamily: "'Poppins', sans-serif",
                        }}
                        fontSize={{ xs: "1rem", sm: "1.05rem", md: "1.1rem" }}
                      >
                        {service.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

        </Container>

      </Container>
    </>
  );
};

export default Banner;
