import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/NavBar';
import Topfooter from './components/Topfooter';
import './App.css';
import Lottie from 'react-lottie-player';
import giftAnimation from './asset/loading.json';
import AnimatedRoutes from './AnimatedRoutes';
import Footer from './components/Footer';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1300);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="gift-loading-container">
        <Lottie
  loop
  animationData={giftAnimation}
  play
  speed={3}  // Set speed to 2x
  style={{ width: 300, height: 300 }}
/>

      </div>
    );
  }

  return (
    <Router>
      <Topfooter />
      <NavBar />
      <AnimatedRoutes />
      {/* WhatsApp Icon */}
      <a href="https://wa.me/6369007033" target="_blank" rel="noopener noreferrer">
        <div className="whatsapp-icon">
          <i className="fab fa-whatsapp"></i>
        </div>
      </a>
    </Router>
  );
}

export default App;
