import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import { Card, CardMedia, CardContent, Button } from "@mui/material";
import logo2 from '../asset/services/image (22).jpg';
import logo3 from '../asset/services/image (3).png';
import logo5 from '../asset/services/image (5).png';
import logo6 from '../asset/services/image (6).png';
import Footer from '../components/Footer';


const services = [
  {
    title: "Gift Wrapping & Packaging",
    image: logo2
  },
  {
    title: "Personalized & Custom Gifts",
    image: logo3
  },
  {
    title: "Corporate Gifting Solutions",
    image: logo6
  },
  // {
  //   title: "Gift Consultation Services",
  //   image: logo4
  // },
  {
    title: "Event Favors & Bulk Orders",
    image: logo5
  },
  // {
  //   title: "Subscription Boxes",
  //   image: logo7
  // },
];
function Service() {
  return (
    <>
      <Container maxWidth={false} sx={{ py: 5, backgroundColor: '#F4F4F4' }}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', }}>
            <Box id="our-services">
              <Typography variant="h6" sx={{ color: '#D2AC47', fontWeight: 500, fontFamily: "'Poppins', sans-serif" }} gutterBottom>
                Our Services
              </Typography>
            </Box>


          </Box>
          <Box>
            <Typography sx={{
              color: '#000000', fontWeight: 500, fontFamily: "'Poppins', sans-serif",
              fontSize: { xs: '3.5vw', sm: '2vw', md: '1.25rem' },
            }} gutterBottom>
              We're here to make your gifting experience as effortless and delightful as possible
            </Typography>
          </Box>

          <Grid container spacing={4} sx={{ mb: 4, mt: 2 }}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 'none',
                  borderRadius: '10px',

                  border: '1px solid #D2AC47'
                }}>
                  <CardMedia
                    component="img"
                    height="250"
                    image={service.image}
                    alt={service.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography align="center" sx={{ fontWeight: 500, fontFamily: "'Poppins', sans-serif", }} fontSize={{ xs: '1rem', sm: '1.05rem', md: '1.1rem' }}

                    >
                      {service.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}


          </Grid>
        </Container>
      </Container>
     <Footer />
    </>
  );
}

export default Service;
