import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Link, IconButton } from '@mui/material';
import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import logo from '../asset/logo.jpg';

const Footer = () => {

    const [customerName, setCustomerName] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [apiFeedback, setApiFeedback] = useState("");
    const [loading, setLoading] = useState(false); // New loading state

    const handleSendEnquiry = async () => {
        if (!customerName || !whatsappNumber) {
            setApiFeedback("Please fill in all fields.");
            return;
        }

        setLoading(true); // Set loading to true when submission starts

        const payload = {
            customerName,
            whatsappNumber,
        };

        try {
            const response = await fetch("https://rajagifts.com/api/enquiry", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (response.ok && data.success) {
                setApiFeedback(data.success);
                setCustomerName("");
                setWhatsappNumber("");
            } else {
                setApiFeedback(data.error || "Something went wrong.");
            }
        } catch (error) {
            console.error("Error sending enquiry:", error);
            setApiFeedback("Failed to send enquiry.");
        } finally {
            setLoading(false); // Reset loading state after submission
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    py: { xs: 3, sm: 4 },
                    px: { xs: 2, sm: 4 },
                    fontWeight: 500,
                    fontFamily: "'Poppins', sans-serif",
                    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)', // Add top shadow

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-around',
                        textAlign: { xs: 'center', md: 'left' },
                        gap: { xs: 3, md: 0 },
                    }}
                >
                    {/* Information Section */}
                    <Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 500,
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            Information
                        </Typography>
                        <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                            {['Home', 'Gallery', 'Features', 'Service'].map((item) => (
                                <Box component="li" key={item} sx={{ mb: 1 }}>
                                    <Link
                                        href={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                        color="inherit"
                                        underline="hover"
                                    >
                                        {item}
                                    </Link>
                                </Box>
                            ))}

                        </Box>
                    </Box>

                    {/* Contact Section */}

                    <Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 500,
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            Contacts
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 500, mt: 2, fontFamily: "'Poppins', sans-serif" }}
                        >
                            <a
                                href="mailto:rajagiftings@gmail.com"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                rajagiftings@gmail.com
                            </a>
                        </Typography>

                        <Typography variant="body1" sx={{ fontWeight: 500, mt: 1, fontFamily: "'Poppins', sans-serif", }}>
                            112/1, Keelakadai Street,<br></br> Virudhunagar,<br></br> Tamilnadu - 626001
                        </Typography>

                        <Typography variant="body1" sx={{ fontWeight: 500, mt: 1, fontFamily: "'Poppins', sans-serif", }}>
                            GST No:<br></br>33ABNPE7458E1ZN
                        </Typography>
                    </Box>

                    {/* Logo and Subscription Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'column' },
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={logo}
                            width="200"
                            alt="Logo"
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                            }}
                        />

                    </Box>

                    {/* Legal Section */}
                    {/* <Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}
                        >
                            Legal
                        </Typography>
                        <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                            {['Terms of use', 'Privacy Policy', 'Copyright', 'Cookies policy'].map((item) => (
                                <Box component="li" key={item} sx={{ mb: 1 }}>
                                    <Link href="#" color="inherit" underline="hover">
                                        {item}
                                    </Link>
                                </Box>
                            ))}
                        </Box>
                    </Box> */}

                     <Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.2rem' },
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            Enquiry Now
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'column' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            mt: 2,
                        }}>
                            <TextField
                                variant="outlined"
                                placeholder="Your Name"
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                                size="small"
                                sx={{
                                    bgcolor: '#fff',
                                    borderRadius: 1,
                                }}
                            />
                            <TextField
                                variant="outlined"
                                placeholder="Your WhatsApp Number"
                                value={whatsappNumber}
                                onChange={(e) => setWhatsappNumber(e.target.value)}
                                size="small"
                                sx={{
                                    bgcolor: '#fff',
                                    borderRadius: 1,
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={handleSendEnquiry}
                                disabled={!customerName || !whatsappNumber || loading} // Disable while loading
                                sx={{
                                    background: 'linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #E1BA53 0%, #D1AE48 50%, #F7EFBA 75%, #BF9936 100%)',
                                    },
                                    fontSize: { xs: '0.8rem', sm: '1rem' },
                                    fontWeight: 600,
                                    fontFamily: "'Poppins', sans-serif",
                                    color: '#000000',
                                    px: { xs: 2, sm: 4 },
                                }}
                            >
                                {loading ? "Sending..." : "Send"} {/* Show loading text */}
                            </Button>
                        </Box>
                        </Box>
                    {/* Social Media Section */}
                    <Box>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.2rem' },
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            Social Media
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                            <IconButton color="inherit" href="https://www.facebook.com/people/Raja-Gifts/pfbid02S9CPgTb5zZj8M4U2q5WihvH46k4F5Jfk8iyUev6XL7ZEUTcpim5rXYg2eX4UUHN4l/?ref=xav_ig_profile_web" target="_blank" rel="noopener noreferrer">
                                <Facebook />
                            </IconButton>
                            <IconButton color="inherit" href="https://www.instagram.com/raja.gifts/" target="_blank" rel="noopener noreferrer">
                                <Instagram />
                            </IconButton>

                            {/* <IconButton color="inherit" href="#">
                                <YouTube />
                            </IconButton> */}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ backgroundColor: '#000', color: '#fff', py: 1 }}>
                <Typography
                    variant="body2"
                    align="center"
                    sx={{
                        wordSpacing: '3px',
                        fontSize: { xs: '0.5rem', sm: '0.6rem', md: '0.8rem' },
                        fontWeight: 300,
                        fontFamily: "'Poppins', sans-serif",
                    }}
                >
                    Copyright © 2024. Raja Gifts & customization, All Rights Reserved
                </Typography>
            </Box>
        </>
    );
};

export default Footer;
