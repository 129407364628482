import React, { useState } from 'react';
import {
  Grid, Box, Typography, IconButton, Button, Drawer,
  List, ListItem, ListItemText, Container, Dialog,
  DialogTitle, DialogContent, TextField, DialogActions
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import logo from '../asset/logo.jpg';
import './NavBar.css';
import { RiMenu3Fill } from 'react-icons/ri';

const NavBar = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', whatsapp: '' });

  const handleDialogOpen = () => {

    setDialogOpen(true);
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    setDrawerOpen(false);
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    console.log('Form Submitted:', formData);
    setDialogOpen(false);
    setFormData({ name: '', whatsapp: '' }); // Reset form
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuOpen = () => {
    setDrawerOpen(true);
  };

  const handleMenuClose = () => {
    setDrawerOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isEnquiryFormOpen, setIsEnquiryFormOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [apiFeedback, setApiFeedback] = useState("");

  const handleOpenEnquiryForm = () => {
    setCustomerName("");
    setWhatsappNumber("");
    setApiFeedback("");
    setIsEnquiryFormOpen(true);
  };

  const handleCloseEnquiryForm = () => {
    setIsEnquiryFormOpen(false);
  };

  const handleSendEnquiry = async () => {
    if (!customerName || !whatsappNumber) {
      setApiFeedback("Please fill in all fields.");
      return;
    }

    const payload = {
      customerName,
      whatsappNumber,
    };

    try {
      const response = await fetch("https://rajagifts.com/api/enquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setApiFeedback(data.success);
        toast.success(data.success);

        handleCloseEnquiryForm()
      } else {
        setApiFeedback(data.error || "Something went wrong.");
        toast.error(data.error);

      }
    } catch (error) {
      console.error("Error sending enquiry:", error);
      toast.error("Failed to send enquiry.");
      setApiFeedback("Failed to send enquiry.");
    }
  };

  return (
    <>
      <Container
        sx={{
          position: { xs: 'sticky', md: 'unset' }, // Ensure this is used only as a style object
          top: { xs: 0, lg: 'auto' },
          zIndex: 1100,
          backgroundColor: '#fff',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adds a subtle bottom shadow

          mb: 1
        }}
        maxWidth={{
          xs: false,  // xs: Full width
          sm: false,   // Optional: you can define widths for other breakpoints too
          md: 'md',   // md: Normal (default MUI max width for md)
        }}>
        {/* Grid container for the top section */}


        <Grid container sx={{ boxShadow: 'none', py: { xs: 0, md: 2 } }} justifyContent="space-between" alignItems="center">
          {/* Logo - Always Visible */}
          <Grid item sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start' }}>
            <img src={logo} width={100} alt="Logo" />
          </Grid>

          {/* Centered Navigation Links for Medium to Large Screens */}
          <Grid item sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
            <List sx={{ display: 'flex', gap: 3 }}>
              <ListItem onClick={scrollToTop} button component={NavLink} to="/" className="navbar_Link" activeClassName="active">Home</ListItem>
              <ListItem onClick={scrollToTop} button component={NavLink} to="/gallery" className="navbar_Link" activeClassName="active">Gallery</ListItem>
              <ListItem onClick={scrollToTop} button component={NavLink} to="/features" className="navbar_Link" activeClassName="active">Features</ListItem>
              <ListItem onClick={scrollToTop} button component={NavLink} to="/service" className="navbar_Link" activeClassName="active">Service</ListItem>
            </List>
            <Box>
              <Button onClick={handleOpenEnquiryForm} variant="contained" sx={{ display: { xs: 'none', md: 'flex', fontFamily: "'Poppins', sans-serif", }, marginLeft: '40px', marginTop: '10px', background: 'linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)' }} fullWidth >
                Enquiry
              </Button>
            </Box>
          </Grid>

          {/* Hamburger Menu Icon for Small Screens */}
          <Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
              <RiMenu3Fill sx={{ fontWeight: 'bold', fontSize: '2rem' }} />
            </IconButton>
          </Grid>
        </Grid>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent black background
              color: '#FFFFFF', // White font color for readability
            },
          }}
        >
          <Grid container sx={{ width: 250 }} direction="column">
            <Grid item display="flex" justifyContent="flex-end" p={2}>
              <IconButton onClick={handleMenuClose} sx={{ color: '#FFFFFF' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <List>
              {['Home', 'Gallery', 'Features', 'Service'].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  component={NavLink}
                  to={text === 'Home' ? '/' : `/${text.toLowerCase().replace(' ', '')}`}

                  onClick={() => {
                    scrollToTop();
                    handleMenuClose();
                  }}
                  activeClassName="active"
                  sx={{
                    color: '#FFFFFF', // Font color
                    '&.active': {
                      backgroundColor: 'rgba(255, 215, 0, 0.1)', // Active state color (golden)
                      fontWeight: 'bold',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 215, 0, 0.1)', // Light golden hover effect
                    },
                  }}
                >
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            <Grid item sx={{ padding: 2 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleOpenEnquiryForm}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  color: '#000',
                  fontFamily: "'Poppins', sans-serif",
                  background: 'linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #E1BA53 0%, #D1AE48 50%, #F7EFBA 75%, #BF9936 100%)',
                  },
                }}
              >
                Enquiry
              </Button>
            </Grid>
          </Grid>
        </Drawer>

      </Container>

      {/* Enquiry Dialog */}
      <Dialog open={isEnquiryFormOpen} onClose={handleCloseEnquiryForm}>
        <DialogTitle
          sx={{ textAlign: "center", fontFamily: "'Poppins', sans-serif", fontWeight: "bold" }}
        >
          Enquiry Form
        </DialogTitle>
        <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Customer Name Field */}
              <TextField
                fullWidth
                margin="dense"
                label="Name"
                name="name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                inputProps={{
                  pattern: "^[a-zA-Z\\s]*$", // Allows only letters and spaces
                }}
                helperText={
                  !/^[a-zA-Z\s]*$/.test(customerName) && customerName !== ''
                    ? 'Name should contain only letters and spaces.'
                    : ''
                }
                error={!/^[a-zA-Z\s]*$/.test(customerName) && customerName !== ''}
              />

              {/* WhatsApp Number Field */}
              <TextField
                fullWidth
                margin="dense"
                label="WhatsApp Number"
                name="whatsapp"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                inputProps={{
                  maxLength: 10,
                  pattern: "^[0-9]{10}$", // Enforces exactly 10 digits
                }}
                helperText={
                  whatsappNumber && !/^[0-9]{10}$/.test(whatsappNumber)
                    ? 'WhatsApp Number must be 10 digits.'
                    : ''
                }
                error={whatsappNumber && !/^[0-9]{10}$/.test(whatsappNumber)}
              />
            </Box>
          </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEnquiryForm} color="error" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            Cancel
          </Button>
          <Button
            onClick={handleSendEnquiry}
            disabled={
              !customerName ||
              !whatsappNumber ||
              !/^[a-zA-Z\s]*$/.test(customerName) ||
              !/^[0-9]{10}$/.test(whatsappNumber)
            }
            sx={{
              fontFamily: "'Poppins', sans-serif",
              background: "linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)",
              color: "#000",
              "&:hover": {
                background: "linear-gradient(45deg, #E1BA53 0%, #D1AE48 50%, #F7EFBA 75%, #BF9936 100%)",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer position="top-center" />
    </>
  );
};

export default NavBar;
