import React from 'react';
import CustomImageList from '../components/CustomImageList';
import Footer from '../components/Footer';
import { Container } from '@mui/material';

function Gallery() {
  return (
    <>
      
      <CustomImageList />
      
      <Footer />
    </>
  );
}

export default Gallery;
