import React, { useState } from 'react';
import { Box, Typography, Paper, Avatar, IconButton, Container } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import logo3 from '../asset/shop1.jpg';
import test1 from '../asset/test1.jpg';
import test2 from '../asset/test2.jpg';
import test3 from '../asset/test3.jpg';

const testimonials = [
    {
        name: "Arshath",
        rating: 4.5,
        review: "The gift shop had an amazing collection of handcrafted items! The staff was friendly and helped me find the perfect gift for my friend.",
        image: test1, // replace with actual image path
    },
    {
        name: "Ramalakshmi",
        rating: 4.7,
        review: "Wonderful shopping experience! Their unique collection of gifts is ideal for any occasion. Highly recommend visiting this store.",
        image: test2, // replace with actual image path
    },
    {
        name: "Sena",
        rating: 4.8,
        review: "The packaging was exquisite, and the products are top-notch. Loved the custom options for personalized gifts!",
        image: test3, // replace with actual image path
    },
];

function Testimonial() {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    };

    const { name, review, rating, image } = testimonials[currentTestimonialIndex];

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;

        return (
            <>
                {[...Array(fullStars)].map((_, index) => (
                    <StarIcon key={index} sx={{ color: '#FFD700' }} />
                ))}
                {halfStar && <StarHalfIcon sx={{ color: '#FFD700' }} />}
            </>
        );
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                height: { xs: '80vh', md: '80vh' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `
                linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                url(${logo3})
              `,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: { xs: 2, md: 4 },
            }}
        >
            <Box>
                <Paper
                    elevation={5}
                    sx={{
                        padding: { xs: 2, md: 4 },
                        maxWidth: { xs: '90%', sm: '500px' },
                        borderRadius: '10px',
                    }}
                >
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        {/* Left Section: Avatar and Name */}
                        <Box display="flex" flexDirection="row" alignItems="center" mr={2} mb={2}>
                            <Avatar
                                alt={name}
                                src={image}
                                sx={{
                                    width: { xs: 60, sm: 80 },
                                    height: { xs: 60, sm: 80 },
                                    mb: 1,
                                }}
                            />
                            <Box sx={{ marginLeft: 2 }}>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: '1rem', sm: '1.2rem' },
                                        fontFamily: "'Poppins', sans-serif",
                                    }}
                                >
                                    {name}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    {renderStars(rating)}
                                    <Typography
                                        fontWeight="bold"
                                        sx={{
                                            fontSize: { xs: '0.8rem', sm: '1rem' },
                                            marginLeft: 1,
                                        }}
                                    >
                                        {rating}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        {/* Right Section: Navigation Arrows */}
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <IconButton
                                onClick={handlePrevious}
                                aria-label="previous testimonial"
                                sx={{
                                    border: '1px solid #be9835',
                                    color:'#be9835',
                                    '&:hover': {
                                        backgroundColor: '#be9835',
                                        color: '#ffffff',
                                    },
                                    marginRight: 2,
                                }}
                            >
                                <ArrowBackIosIcon
                                    sx={{
                                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                                        
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={handleNext}
                                aria-label="next testimonial"
                                sx={{
                                    border: '1px solid #be9835',
                                    color: '#be9835',
                                    '&:hover': {
                                        backgroundColor: '#be9835',
                                        color: '#ffffff'

                                    },
                                }}
                            >
                                <ArrowForwardIosIcon
                                    sx={{
                                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                                        
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '0.9rem', sm: '1rem' },
                                fontWeight: 500,
                                fontFamily: "'Poppins', sans-serif",
                                marginTop: 2,
                            }}
                        >
                            {review}
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

export default Testimonial;
