import React, { useState, useEffect, useRef } from "react";
import {
  ImageList,
  ImageListItem,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Skeleton,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // WhatsApp icon
import backg from '../asset/back.jpg'
import frame from '../asset/Frame.png'
function Gallery() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const observer = useRef();

  const fetchPhotos = async (page = 1, category = "All") => {
    setLoading(true);
    try {
      const categoryQuery = category !== "All" ? `&category=${category}` : "";
      const response = await fetch(
        `https://rajagifts.com/api/gallery/appGallery?productsPerPage=20&page=${page}${categoryQuery}`
      );
      const data = await response.json();

      if (Array.isArray(data.products)) {
        setPhotos((prevPhotos) => [
          ...prevPhotos,
          ...data.products.map((item) => ({
            id: item.product_id,
            img: item.image_url,
            price: item.price,
            alt: `Image ${item.product_id}`,
            name: `Product ${item.product_id}`,
            details: `Details about product ${item.product_id}`,
          })),
        ]);
      } else {
        console.error("Invalid products data:", data.products);
      }

      setCurrentPage(parseInt(data.currentPage, 10) || 1);
      setTotalPages(parseInt(data.totalPages, 10) || 1);
    } catch (error) {
      console.error("Error fetching photos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhotos(currentPage);
  }, []);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && currentPage < totalPages) {
          fetchPhotos(currentPage + 1, selectedCategory);
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );
    const element = document.querySelector("#scroll-anchor");
    if (element) observer.current.observe(element);

    return () => observer.current.disconnect();
  }, [currentPage, totalPages, selectedCategory]);

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setPhotos([]); // Clear previous photos
    setCurrentPage(1); // Reset page to 1
    fetchPhotos(1, category); // Fetch photos for the selected category
  };

  const categories = [
    "All",
    "Brass & Copper Idols",
    "German Silver",
    "Pooja Articles",
    "Couple Gift Items",
    "Miniature",
    "Jaipur Handicrafts",
    "Wooden Handicrafts",
    "Home Decor",
    "Windchimes",
    "Keychains",
    "Clocks and Photo Frames",
    "Bags and Wallets",
    "Household Items",
    "Kids: Battery Toys and Soft Toys",
    "Kids: Imported Stationery Items",
    "Kids: Wooden Montessori Toys",
    "Customised Gifts"
  ];

  const handleImageClick = async (photo) => {
    try {
      const response = await fetch(
        `https://rajagifts.com/api/gallery/getDetails/${photo.id}`
      );
      const data = await response.json();
      setSelectedProduct({
        ...photo,
        name: data.name,
        price: data.price,
        description: data.description,
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      // Fallback to showing basic product info if fetch fails
      setSelectedProduct(photo);
    }
  };

  const scrollRef = useRef(null);
  const autoScrollInterval = useRef(null);

  useEffect(() => {
    const startAutoScroll = () => {
      autoScrollInterval.current = setInterval(() => {
        if (scrollRef.current) {
          const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
          if (scrollRef.current.scrollLeft >= maxScrollLeft) {
            scrollRef.current.scrollLeft = 0; // Loop back to start
          } else {
            scrollRef.current.scrollLeft += 2; // Scroll step
          }
        }
      }, 20); // Adjust timing for smoothness
    };

    startAutoScroll();

    // Cleanup on unmount
    return () => clearInterval(autoScrollInterval.current);
  }, []);

  const handleMouseEnter = () => {
    clearInterval(autoScrollInterval.current); // Pause on hover
  };

  const handleMouseLeave = () => {
    autoScrollInterval.current = setInterval(() => {
      if (scrollRef.current) {
        const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
        if (scrollRef.current.scrollLeft >= maxScrollLeft) {
          scrollRef.current.scrollLeft = 0;
        } else {
          scrollRef.current.scrollLeft += 2;
        }
      }
    }, 20);
  };

  // enquiry

  const [isEnquiryFormOpen, setIsEnquiryFormOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [apiFeedback, setApiFeedback] = useState("");

  const handleOpenEnquiryForm = () => {
    setCustomerName("");
    setWhatsappNumber("");
    setApiFeedback("");
    setIsEnquiryFormOpen(true);
  };

  const handleCloseEnquiryForm = () => {
    setIsEnquiryFormOpen(false);
  };

  const handleSendEnquiry = async () => {
    if (!selectedProduct) return;

    const payload = {
      productId: selectedProduct.id,
      customerName,
      whatsappNumber,
    };

    try {
      const response = await fetch("https://rajagifts.com/api/enquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      // Log the full response to verify data
      console.log("API response:", data);

      if (response.status === 200) {
        setApiFeedback(data.success);
        toast.success(data.success);
        handleCloseEnquiryForm();
        handleCloseModal();
      } else {
        setApiFeedback(data.error);
        toast.error(data.error);
        handleCloseEnquiryForm();
        handleCloseModal();
      }

    } catch (error) {
      console.error("Error sending enquiry:", error);
      setApiFeedback("Failed to send enquiry.");
      toast.error("Failed to send enquiry.");
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        py: 5,
        px: { md: 20 },
        backgroundImage: `
      
      url(${frame})
    `,
        backgroundSize: "cover", // Adjusts the image to cover the entire area
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
        backgroundPosition: "center", // Centers the image
      }}
    >
      <Box>
        {/* Category Filter */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          {isSmallScreen ? (
            // Dropdown for Small Screens
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mr: 2,
                  fontFamily: "'Sedan SC', sans-serif",
                  fontSize: { xs: '1.3rem', sm: '2rem' }
                }}
              >
                Products
              </Typography>

              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  variant="outlined"
                  sx={{
                    bgcolor: "#FFF",
                    fontWeight: 'bold',
                    "& .MuiSelect-select": {
                      textAlign: "center",
                    },
                  }}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category} sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            // Horizontal Scrollable Buttons for Larger Screens with Auto-Scroll
            <Box
              ref={scrollRef}
              sx={{
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                scrollbarWidth: "none", // For browsers that support it
                msOverflowStyle: "auto", // For older IE support
                '&::-webkit-scrollbar': {
                  height: 6,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aaa',
                  borderRadius: 3,
                },
              }}
              onWheel={(e) => {
                e.preventDefault();
                scrollRef.current.scrollLeft += e.deltaY;
              }}
              onMouseEnter={handleMouseEnter} // Pause on hover
              onMouseLeave={handleMouseLeave} // Resume on leave
            >
              {categories.map((category, index) => (
                <Button
                  key={`${category}-${index}`}
                  variant="contained"
                  color={selectedCategory === category ? "secondary" : "primary"}
                  sx={{
                    m: 1,
                    borderRadius: 2,
                    whiteSpace: "normal",
                    backgroundColor:
                      selectedCategory === category ? "#2A2A2A" : "#ffffff",
                    color: selectedCategory === category ? "#FFF" : "#535353",
                    "&:hover": {
                      backgroundColor: "#2A2A2A",
                      color: "#FFF",
                    },
                    flexShrink: 0,
                  }}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category}
                </Button>
              ))}
            </Box>
          )}
        </Box>

        <ImageList
          variant="quilted"
          cols={isSmallScreen ? 2 : 4}
          gap={10}
          sx={{
            padding: 2,
          }}
        >
          {photos.map((item, index) => (
            <ImageListItem
              key={item.id || index}
              sx={{
                position: "relative", // Enables the black shade positioning
                borderRadius: 3,
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(item)}
            >
              {item.img ? (
                <>
                  <img
                    src={item.img}
                    alt={item.alt}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  {/* Black shade with price */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      background: "linear-gradient(to top, rgba(0, 0, 0, 2), rgba(0, 0, 0, 0.1))",
                      color: "white",
                      padding: "8px",
                      textAlign: "start",
                      fontSize: "1rem", // Adjust as needed
                    }}
                  >
                    &#8377; {item.price}

                  </Box>
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                />
              )}
            </ImageListItem>
          ))}

          {/* Skeleton for loading */}
          {loading &&
            Array.from(new Array(8)).map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rectangular"
                animation="wave"
                width="100%"
                height={isSmallScreen ? "150px" : "250px"} // Adjust the size dynamically
                sx={{
                  borderRadius: 3,
                  aspectRatio: "1 / 1", // Ensures the skeleton is always square
                }}
              />
            ))}
        </ImageList>


        {/* Infinite Scroll Anchor */}
        <div id="scroll-anchor" style={{ height: 1, width: "100%" }}></div>

        {/* Modal Dialog for Product Details */}
        <Dialog
          open={!!selectedProduct}
          onClose={handleCloseModal}
          maxWidth="lg"
          PaperProps={{
            style: {
              borderRadius: "16px",
            },
          }}
        >
          {selectedProduct && (
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "flex-start",
                justifyContent: "center",
                background: "#f4f4f4",
                color: "#000000",
                padding: { xs: "12px", sm: "16px", md: "24px", lg: "32px" },
                gap: { xs: "12px", sm: "16px", md: "24px" },
                maxHeight: "100%", // Prevents dialog from overflowing vertically
                overflowY: "auto",
              }}
            >
              {/* Left Column: Product Image */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "3px", sm: "7px", md: "16px" },
                }}
              >
                <img
                  src={selectedProduct.img}
                  alt={selectedProduct.alt}
                  style={{
                    width: "100%",  // Make image responsive
                    height: "auto", // Maintain aspect ratio
                    maxWidth: "100%", // Ensure image does not exceed container width
                    borderRadius: "12px",
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>

              {/* Right Column: Details */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  overflowY: "auto", // Enable scrolling when the content overflows
                  maxHeight: "100%", // Responsive max height
                  padding: { xs: "8px", sm: "16px", md: "24px" },
                }}
              >
                {/* Name */}
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
                    fontWeight: 500,
                    lineHeight: "1.3",
                  }}
                >
                  {selectedProduct.name}
                </Typography>

                {/* Price */}
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "14px", sm: "16px", md: "17px" },
                    fontWeight: 500,
                    mt: { md: 1 },
                  }}
                >
                  Price: ₹{selectedProduct.price}
                </Typography>

                {/* Description Header */}
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "16px", sm: "18px", md: "18px" },
                    fontWeight: 500,
                    mt: { md: 2 },
                  }}
                >
                  Description:
                </Typography>

                {/* Description Card */}
                <Card
                  sx={{
                    borderRadius: "12px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    background: "#f9f9f9",
                    padding: { xs: "6px", sm: "8px", md: "15px" },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px", md: "16px" },
                        lineHeight: "1.6",
                        color: "#333",
                      }}
                    >
                      {selectedProduct.description}
                    </Typography>
                  </CardContent>
                </Card>

                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "12px",
                    marginTop: "16px",
                    bottom: 0,
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  {/* WhatsApp Button */}
                  <Button
                    variant="contained"
                    startIcon={<WhatsAppIcon />}
                    onClick={() => {
                      const phoneNumber = "916369007033";
                      const message = `
Hello! I'm interested in learning more about this product:
*Product Name:* ${selectedProduct.name}
*Price:* ₹${selectedProduct.price}
*Description:* ${selectedProduct.description}
Can you please provide more details?`;
                      const encodedMessage = encodeURIComponent(message.trim());
                      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
                      window.open(whatsappUrl, "_blank");
                    }}
                    sx={{
                      fontFamily: "Poppins",
                      background: "linear-gradient(45deg, #25D366, #128C7E)",
                      color: "#FFF",
                      borderRadius: "50px",
                      padding: { xs: "8px 16px", sm: "10px 20px", md: "12px 24px" },
                      textTransform: "none",
                      fontSize: { xs: "14px", sm: "15px", md: "16px" },
                      "&:hover": {
                        background: "linear-gradient(45deg, #128C7E, #075E54)",
                      },
                    }}
                  >
                    WhatsApp
                  </Button>


                  {/* Enquiry Button */}
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleOpenEnquiryForm();
                    }}
                    sx={{
                      fontFamily: "Poppins",
                      background: "linear-gradient(45deg, #BF9936, #D1AE48)",
                      color: "#FFF",
                      borderRadius: "50px",
                      padding: { xs: "8px 16px", sm: "10px 20px", md: "12px 24px" },
                      textTransform: "none",
                      fontSize: { xs: "14px", sm: "15px", md: "16px" },
                      "&:hover": {
                        background: "linear-gradient(45deg, #D1AE48, #E1BA53)",
                      },
                    }}
                  >
                    Enquiry
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          )}
        </Dialog>

        {/* Enquiry Form Modal */}
        <Dialog open={isEnquiryFormOpen} onClose={handleCloseEnquiryForm}>
          <DialogTitle
            sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}
          >
            Enquiry Form
          </DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Customer Name Field */}
              <TextField
                fullWidth
                margin="dense"
                label="Name"
                name="name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                inputProps={{
                  pattern: "^[a-zA-Z\\s]*$", // Allows only letters and spaces
                }}
                helperText={
                  !/^[a-zA-Z\s]*$/.test(customerName) && customerName !== ''
                    ? 'Name should contain only letters and spaces.'
                    : ''
                }
                error={!/^[a-zA-Z\s]*$/.test(customerName) && customerName !== ''}
              />

              {/* WhatsApp Number Field */}
              <TextField
                fullWidth
                margin="dense"
                label="WhatsApp Number"
                name="whatsapp"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                inputProps={{
                  maxLength: 10,
                  pattern: "^[0-9]{10}$", // Enforces exactly 10 digits
                }}
                helperText={
                  whatsappNumber && !/^[0-9]{10}$/.test(whatsappNumber)
                    ? 'WhatsApp Number must be 10 digits.'
                    : ''
                }
                error={whatsappNumber && !/^[0-9]{10}$/.test(whatsappNumber)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseEnquiryForm}
              color="error"
              sx={{ fontFamily: "'Poppins', sans-serif" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSendEnquiry}
              disabled={
                !customerName ||
                !whatsappNumber ||
                !/^[a-zA-Z\s]*$/.test(customerName) ||
                !/^[0-9]{10}$/.test(whatsappNumber)
              }
              sx={{
                fontFamily: "'Poppins', sans-serif",
                background: 'linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)',
                color: '#000',
                '&:hover': {
                  background: 'linear-gradient(45deg, #E1BA53 0%, #D1AE48 50%, #F7EFBA 75%, #BF9936 100%)',
                },
              }}
            >
              Submit Enquiry
            </Button>
          </DialogActions>
        </Dialog>


        <ToastContainer position="top-center" />


      </Box>
    </Container>
  );
}

export default Gallery;
