import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Container, Button, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import features from '../asset/features1.png';
import frame from '../asset/Frame.png';
import { ImageList, ImageListItem, useMediaQuery, Dialog, DialogContent, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backg from '../asset/back.jpg'

const Features = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top
        navigate('/gallery');
    };
    
    const [galleryImages, setGalleryImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        // Fetch gallery images from the API
        fetch('https://rajagifts.com/api/landing', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(data => {
                // Ensure we fetch only the first 5 images
                const updatedData = data.slice(0, 5).map((item, index) => {
                    if (index < 3) {
                        // First 2 images as portrait (phone screen style)
                        return { ...item, rows: 2, cols: 1 };
                    } else {
                        // Next 3 images as square
                        return { ...item, rows: 1, cols: 1 };
                    }
                });
                setGalleryImages(updatedData);
            })
            .catch(error => {
                console.error('Error fetching gallery images:', error);
            });
    }, []);




    const handleClickOpen = (img) => {
        setSelectedImage(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };
    return (
        <>
            <Container maxWidth={false} sx={{ py: 5, backgroundColor: '#F4F4F4' }}>
                <Container>
                    {/* Why Choose Us Section */}
                    <Grid container spacing={4}>
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <Box p={5} sx={{ padding: { xs: 0, md: 5 } }}>
                                <Typography variant="h6" fontWeight="bold" mb={3} color="error" sx={{ mb: 2, color: '#D2AC47', fontWeight: 500, fontFamily: "'Poppins', sans-serif", }} gutterBottom>
                                    Our Features
                                </Typography>
                                <Typography variant="h5" fontWeight="bold" mb={1} sx={{ fontFamily: "'Poppins', sans-serif", }}>
                                    Why choose Us
                                </Typography>
                                <Typography variant="body1" mb={3} sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    At Raja Gifts & customization, we pride ourselves on
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Quality & Uniqueness
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    Every item is carefully selected for its craftsmanship, quality, and charm.
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Exceptional Service
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    We’re here to help you find the perfect gift and make the experience enjoyable.
                                </Typography>

                                <Typography variant="h6" fontWeight="bold" mb={2} sx={{ fontWeight: 600, fontFamily: "'Poppins', sans-serif", }}>
                                    Sustainable Choices
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", }} paragraph>
                                    We care about the planet and seek out eco-friendly options whenever possible.
                                </Typography>

                            </Box>
                        </Grid>

                        {/* Right Column with Image */}
                        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                            <Box
                                component="img"
                                src={features}
                                sx={{
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: 2,
                                    maxWidth: '100%',
                                    height: 'auto',
                                }}
                            />
                        </Grid>
                    </Grid>


                </Container>
            </Container>

            <Container maxWidth={false} sx={{
                backgroundImage: `
                linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), 
                url(${backg})
              `,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                py: 5
            }}>
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 5 }}>
                        <Box>
                            <Typography variant="h6" sx={{ color: '#D2AC47', fontWeight: 500, fontFamily: "'Poppins', sans-serif", }} gutterBottom>
                                Our gallery
                            </Typography>
                        </Box>


                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 5 }}>
                        <Box>
                            <Typography sx={{
                                color: '#000000', fontWeight: 500, fontFamily: "'Poppins', sans-serif",
                                fontSize: { xs: '3.5vw', sm: '2vw', md: '1.25rem' },
                            }} gutterBottom>
                                At Raja Gifts & customization, we pride ourselves on
                            </Typography>
                        </Box>
                    </Box>
                    <ImageList
                        sx={{ width: '100%', height: 'auto' }}
                        variant="quilted"
                        cols={2} // Adjusted to 3 columns to fit the 5 images properly
                        rowHeight="auto" // Let the height adjust automatically
                        gap={15}
                    >
                        {galleryImages.map((item) => (
                            <ImageListItem
                                key={item._id}
                                cols={item.cols}
                                rows={item.rows}
                                onClick={() => handleClickOpen(item.imageurl)}
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '& img': {
                                        transition: 'transform 0.3s ease',
                                        transform: 'scale(1)',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        },
                                    },
                                }}
                            >
                                <img
                                    src={item.imageurl}
                                    alt={item.position}
                                    loading="lazy"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>


                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleClick}
                        sx={{
                            background: 'linear-gradient(45deg, #BF9936 0%, #F7EFBA 25%, #D1AE48 50%, #E1BA53 100%)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #E1BA53 0%, #D1AE48 50%, #F7EFBA 75%, #BF9936 100%)',
                            },
                            fontSize: { xs: '0.6rem', sm: '0.9rem' },
                            fontWeight: 600, fontFamily: "'Poppins', sans-serif",
                            color: '#000000',
                            padding: { xs: '6px 12px', sm: '8px 16px' }
                        }}
                    >
                        Load More
                    </Button>
                </Container>
            </Container>

            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Features;
